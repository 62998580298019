import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { get } from '../../services/auth.services';
import { setAuthenticated } from '../../redux/auth.redux';
import { setCurrentProject } from '../../redux/projects.redux';
import { useDispatch } from 'react-redux';
import ListSkeleton from '../../components/Skeleton/ListSkeleton';
import EmptyPage from '../../components/EmptyPage/EmptyPage';
import { useHistory } from 'react-router-dom';

import './ProjectChoice.css';

const ProjectChoice: React.FC = () => {
  const history = useHistory();
  const [projectsList, setProjectsList] = useState<any[]>([]);
  const [showLoading, setShowLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const projectSelected = localStorage.getItem('project');
    setShowLoading(true);
    if (projectsList.length === 0) {
      get('/self/projects', { limit: 1000, order: 'project.name:ASC' })
        .then((response) => {
          setProjectsList(response.data);
          setShowLoading(false);
          if (response.data.length === 1) {
            dispatch(setCurrentProject(parseInt(projectSelected!)));
          }
        })
        .catch((error) => {
          setShowLoading(false);
          if (error.status === 401) {
            dispatch(setAuthenticated(false));
          }
        });
    }
  }, []);

  function selectCurrentProject(project: any) {
    localStorage.setItem('project', project.id);
    dispatch(setCurrentProject(project.id));
    history.replace('/nfc');
  }

  function isDateBeforeToday(date: any) {
    return new Date(date) < new Date(new Date().toDateString());
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle class="ion-text-center">Choix du projet</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
        {showLoading ? (
          <ListSkeleton list={6} />
        ) : projectsList.length > 0 ? (
          <>
            <IonList>
              {projectsList.map((data, index: number) => {
                if (!isDateBeforeToday(data.project.audienceDateStop)) {
                  return (
                    <IonItem
                      key={index}
                      onClick={() => selectCurrentProject(data?.project)}
                      button
                    >
                      <IonLabel>
                        <h2>{data?.project?.name}</h2>
                      </IonLabel>
                    </IonItem>
                  );
                }
              })}
            </IonList>
          </>
        ) : (
          <EmptyPage message={'Aucun resultat'} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default ProjectChoice;
