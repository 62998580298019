import React, { useCallback, useEffect, useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonModal,
  IonButtons,
  IonButton,
  IonIcon,
  IonFab,
  IonFabButton,
} from '@ionic/react';
import {
  lockClosedOutline,
  radioOutline,
  arrowBackOutline,
  add,
} from 'ionicons/icons';
import './SupportDetailsModal.css';
import Loading from '../Loading/Loading';
import { get } from '../../services/auth.services';
import { setAuthenticated } from '../../redux/auth.redux';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import TransactionDetails from '../TransactionDetails/TransactionsDetails';
import 'moment/locale/fr';
import NewSaleModal from '../NewSaleModal/NewSaleModal';

interface modalProps {
  isOpen: boolean;
  currentCard: any;
  closeModal: () => void;
  reload: (cardLabel: any) => void;
}

const SupportDetailsModal: React.FC<modalProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [walletsList, setWalletsList] = useState([]);
  const [transactionsList, setTransactionsList] = useState<any>();
  const [currentWallet, setCurrentWallet] = useState<any>();
  const [openModalNewSale, setOpenModalNewSale] = useState(false);
  const [copyText, setCopyText] = useState<null | string>(null);

  const { currentCard } = props;
  const projectSelected = localStorage.getItem('project');
  const dispatch = useDispatch();
  moment.locale('fr');

  const handleSelectWallet = useCallback(
    (wallet: any) => {
      if (!loading) {
        setLoading(true);
      }
      setTransactionsList(null);
      setCurrentWallet(wallet);
      get('/wallets/' + wallet.id + '/transactions', {
        order: 'createdAtUTC:DESC',
      })
        .then((msg) => {
          const tmp: any = {};
          for (const transaction of msg.data) {
            if (!transaction.createdAtUTC) {
              transaction.createdAtUTC = transaction.createdAt;
            }
            const key = moment(transaction.createdAtUTC).diff(moment(), 'days');
            let label = ucfirst(moment(transaction.createdAtUTC).fromNow());
            if (key !== 0) {
              label = ucfirst(moment(transaction.createdAtUTC).fromNow());
            }
            if (!is_def(tmp[key])) {
              tmp[key] = {
                label: label,
                entries: [],
              };
            }
            tmp[key]['entries'].push(transaction);
          }
          setTransactionsList(tmp);
        })
        .catch((error) => {
          setLoading(false);
          if (error.status === 401) {
            dispatch(setAuthenticated(false));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch, loading]
  );
  useEffect(() => {
    setLoading(true);
    get('/nfctags/' + currentCard.uid + '/wallets', {
      project_id: projectSelected,
    })
      .then((response) => {
        setWalletsList(response.data);
        if (response.data && response.data.length > 0) {
          setCurrentWallet(response.data[0]);
          handleSelectWallet(response.data[0]);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.status === 401) {
          dispatch(setAuthenticated(false));
        }
      });
  }, [currentCard, dispatch, projectSelected]);

  function ucfirst(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function is_def(obj: any) {
    return typeof obj !== 'undefined';
  }

  function handleCloseNewSaleModal() {
    setOpenModalNewSale(false);
    props.reload(currentCard.label);
  }

  return (
    <IonModal isOpen={props.isOpen}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Détails</IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={props.closeModal}>
              <IonIcon icon={arrowBackOutline} style={{ color: '#374957' }} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div
          className={
            'top-card-container' + (currentCard.isLocked ? ' locked' : '')
          }
        >
          {currentCard.isLocked && (
            <div className="card-locked">Carte bloquée</div>
          )}
          <div className={'card'}>
            <div className={'circle'}></div>
            <div
              className={'number'}
              onClick={() => {
                navigator.clipboard.writeText(
                  'Label : ' + currentCard.label + ' / UID : ' + currentCard.uid
                );
                setCopyText('Copié !');
                setTimeout(() => {
                  setCopyText(null);
                }, 2000);
              }}
            >
              {currentCard.label}{' '}
              <span className={'uid-card'}>
                ({currentCard.uid}) {copyText ? copyText : ''}
              </span>
            </div>
            <div className={'amount'}>
              {currentCard.defaultWallet?.amount}{' '}
              {currentCard.defaultWallet?.walletConfig?.unitSymbole}
            </div>
            <div className={'card-action-container'}>
              <button className={'btn-lock red'}>
                <IonIcon icon={lockClosedOutline} />
              </button>
              <button className={'btn-lock'}>
                <IonIcon icon={radioOutline} />
              </button>
            </div>
          </div>
        </div>
        {walletsList && currentWallet && walletsList.length >= 2 && (
          <div className={'wallets-list-container'}>
            {walletsList.map((wallet: any, index) => {
              return (
                <div
                  className={
                    'wallet-element' +
                    (currentWallet.id === wallet.id ? ' selected' : '')
                  }
                  key={index}
                  onClick={() => handleSelectWallet(wallet)}
                >
                  <div className="wallet-name">
                    <strong>{wallet?.wallet?.name}</strong>
                  </div>
                  <div className="wallet-amount">
                    {wallet.amount} {wallet?.wallet?.unitSymbole}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="sales-history-title">Historique des transactions</div>
        <div className={'transactions-list-container'}>
          {transactionsList &&
            Object.keys(transactionsList).length > 0 &&
            Object.keys(transactionsList).map((key: any, i) => (
              <div className="day" key={i}>
                <div className="label">{transactionsList[key].label}</div>
                {transactionsList[key].entries.map(
                  (transaction: any, j: number) => (
                    <TransactionDetails
                      key={j}
                      transaction={transaction}
                      currentWallet={currentWallet}
                    />
                  )
                )}
              </div>
            ))}
        </div>
        <IonFab
          className="fab-new-sale"
          slot="fixed"
          vertical="bottom"
          horizontal="end"
        >
          <IonFabButton
            disabled={currentCard.isLocked}
            onClick={() => setOpenModalNewSale(true)}
          >
            <IonIcon style={{ fontSize: '30px' }} icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>
        <Loading isLoading={loading} />
        <NewSaleModal
          isOpen={openModalNewSale}
          currentCard={currentCard}
          close={handleCloseNewSaleModal}
        />
      </IonContent>
    </IonModal>
  );
};

export default SupportDetailsModal;
