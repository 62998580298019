import React, { useEffect, useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonModal,
  IonButtons,
  IonButton,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonAlert,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import Loading from '../Loading/Loading';
import { get, post } from '../../services/auth.services';
import { setAuthenticated } from '../../redux/auth.redux';
import { useDispatch } from 'react-redux';
import './NewSaleModal.css';

interface modalProps {
  isOpen: boolean;
  currentCard: any;
  close: () => void;
}

const NewSaleModal: React.FC<modalProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [sheetcash, setSheetcash] = useState<any>();
  const [productSelected, setProductSelected] = useState<any>();
  const [quantity, setQuantity] = useState<any>(1);
  const [showAlert, setShowAlert] = useState(false);

  const projectSelected = localStorage.getItem('project');
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    get('/projects/' + projectSelected + '/products', {
      type: 3,
      'priceWithVat|eq': 0,
    })
      .then((response) => {
        setProductsList(response.data);
        get('/self/sheetcashs', {
          project_id: projectSelected,
          'pos.type': 'backoffice',
        })
          .then((sheetcash) => {
            setSheetcash(sheetcash.data[0]);
          })
          .catch((error) => {
            setLoading(false);
            if (error.status === 401) {
              dispatch(setAuthenticated(false));
            }
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        setLoading(false);
        if (error.status === 401) {
          dispatch(setAuthenticated(false));
        }
      });
  }, [dispatch, projectSelected]);

  function handleSelectProduct(e: any) {
    setProductSelected(e.target.value);
  }

  function handleValidNewSale() {
    setShowAlert(true);
  }

  function validSale() {
    setLoading(true);
    post('/sheetcashs/' + sheetcash?.id + '/baskets', {
      accepted: true,
      tagUid: props.currentCard.uid,
      items: [
        {
          product: productSelected.id,
          quantity: quantity,
        },
      ],
    })
      .then(() => {
        props.close();
      })
      .catch((error) => {
        if (error.status === 401) {
          dispatch(setAuthenticated(false));
        } else {
          alert('Une erreur est survenue lors de la recharge');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getCredits() {
    return productSelected.cashlessValue * quantity;
  }

  return (
    <IonModal isOpen={props.isOpen}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Nouvelle vente</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={props.close}>
              <IonIcon icon={closeOutline} style={{ color: '#374957' }} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding container-new-sale">
        <div className={'new-sale-title'}>Choisir une formule : </div>

        <div className={'products-list-container'}>
          {productsList && (
            <IonSelect
              placeholder="Liste des formules disponibles"
              onIonChange={handleSelectProduct}
            >
              {productsList.length > 0 &&
                productsList.map((product: any, index) => (
                  <IonSelectOption value={product} key={index}>
                    {product.marketingName}
                  </IonSelectOption>
                ))}
            </IonSelect>
          )}
        </div>
        <div className={'quantity-title'}>Choisir la quantité :</div>
        <div className={'input-quantity-container'}>
          <input
            value={quantity}
            type="tel"
            className={'input-quantity'}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </div>
        <div className={'input-quantity-container'}>
          <button
            className={
              'btn ' +
              (!productSelected || (!quantity && quantity <= 0)
                ? ' disabled'
                : '')
            }
            onClick={handleValidNewSale}
            id="present-alert"
          >
            Valider
          </button>
          {productSelected && (
            <IonAlert
              isOpen={showAlert}
              onDidDismiss={() => setShowAlert(false)}
              cssClass="my-custom-class"
              header={getCredits() + ' Crédits'}
              message={
                'Vous êtes sur le point de pousser ' +
                getCredits() +
                ' crédit(s) sur ce support. Êtes vous sûr ?'
              }
              buttons={[
                {
                  text: 'Annuler',
                  role: 'cancel',
                  cssClass: 'cancel-sale',
                },
                {
                  text: 'Confirmer',
                  handler: validSale,
                },
              ]}
            />
          )}
        </div>
        <Loading isLoading={loading} />
      </IonContent>
    </IonModal>
  );
};

export default NewSaleModal;
