import {
  IonContent,
  IonButton,
  useIonAlert,
  IonRow,
  IonCol,
  IonImg,
  IonSpinner,
} from '@ionic/react';
import React, { useState } from 'react';
import './Login.css';
import { post } from '../../services/auth.services';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAuthenticated } from '../../redux/auth.redux';
import audienceLogo from '../../assets/Audience_logo.svg';
import audienceStar from '../../assets/Audiance_star.svg';

const Login: React.FC = () => {
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [presentAlert] = useIonAlert();
  const dispatch = useDispatch();

  function postRefreshToken(token: string) {
    return post('/refresh_token', { refresh_token: token }, {}, '');
  }

  function initializeRefreshtokenPooling(refreshToken: string) {
    // 2 400 000ms = 40min
    setInterval(() => postRefreshToken(refreshToken), 2400000);
  }

  function handleLogin() {
    setShowLoading(true);
    post('/login', {
      username: email,
      password: password,
    })
      .then((response) => {
        dispatch(setAuthenticated(true));
        setShowLoading(false);
        localStorage.setItem('token', response.token);
        localStorage.setItem('refreshToken', response.refresh_token);
        initializeRefreshtokenPooling(response.refresh_token);
        history.replace('/project-choice');
      })
      .catch((e) => {
        setShowLoading(false);
        presentAlert({
          header: 'Erreur',
          message: "Code d'erreur : " + e?.body?.code,
          subHeader: e?.body?.message,
          buttons: ['OK'],
        });
      });
  }

  return (
    <IonContent class="ion-padding">
      <div className={'login-page'}>
        <IonRow className={'login-header'}>
          <IonCol className={'title-container'}>
            <div className={'main-title-container'}>Helpdesk</div>
            <div className={'subtitle-container'}>
              by Audience <span>*</span>
            </div>
          </IonCol>

          <IonImg src={audienceStar} className={'background-img'} />
        </IonRow>
        <div className={'login-body'}>
          <input
            type="email"
            className={'support-label'}
            placeholder={'Email'}
            onChange={(e: any) => setEmail(e.target.value)}
            value={email}
          />
          <input
            type="password"
            className={'support-label'}
            placeholder={'Password'}
            onChange={(e: any) => setPassword(e.target.value)}
            value={password}
          />
          <br />
          <IonButton
            className={'login-button'}
            onClick={handleLogin}
            disabled={email === '' || password === '' || showLoading}
          >
            {showLoading && <IonSpinner slot="start" name="crescent" />}
            Login
          </IonButton>
        </div>

        <div className=" login-footer">
          <IonImg src={audienceLogo} className={'login-audience-logo'} />
        </div>
      </div>
    </IonContent>
  );
};

export default Login;
